<!-- 历史保函 -->
<template>
  <div class="invoice">
    <el-card style="border-radius: 10px; overflow-y: auto; height: 820px">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="orderNum" placeholder="订单编号"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="projectName" placeholder="项目名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="invoiceStatus" placeholder="发票状态">
            <el-option
              v-for="item in invoiceArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-date-picker
              v-model="applyDate"
              type="date"
              placeholder="开票日期"
              style="width: 100%"
            ></el-date-picker>
          </div>
        </el-col>
        <el-col :span="7">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%; margin-top: 20px">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="orderNum"
          label="订单编号"
          width="240"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="biaoduanName"
          label="项目名称"
        ></el-table-column>
        <el-table-column align="center" label="订单日期">
          <template slot-scope="scope">
            <p>{{ moment(scope.row.orderCreateDate).format("YYYY-MM-DD") }}</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="guaFee"
          label="保费（元）"
        ></el-table-column>
        <el-table-column align="center" label="发票状态">
          <template slot-scope="scope">
            <p v-show="scope.row.ticketStatus == 0">未申请</p>
            <p v-show="scope.row.ticketStatus == 1">开票中</p>
            <p v-show="scope.row.ticketStatus == 2">已开票</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发票申请时间">
          <template slot-scope="scope">
            <p v-if="scope.row.invoiceCreateDate != ''">
              {{ moment(scope.row.invoiceCreateDate).format("YYYY-MM-DD") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ticketType"
          label="发票类型"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="scope.row.ticketStatus == 0"
              @click="huixian(scope.row)"
              >申请发票</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="scope.row.ticketStatus == 1 || scope.row.ticketStatus == 2"
              @click="checkDetails(scope.row)"
              >发票详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          style="text-align: center; margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="this.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
      <div
        style="
          text-align: center;
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid #ccc;
        "
      >
        <el-button type="primary" @click="getBack">返回</el-button>
      </div>
    </el-card>
    <el-drawer title="发票申请" :visible.sync="invoice" size="50%">
      <div
        style="
          padding: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
      >
        <span>请选择开票类型：</span>
        <div style="display: flex; margin-top: 20px; margin-bottom: 20px">
          <el-card
            shadow="hover"
            style="cursor: pointer"
            :class="{ elCard: isActive }"
            @click.native="common"
            >增值税普通发票</el-card
          >
          <el-card
            shadow="hover"
            style="margin-left: 20px; cursor: pointer"
            :class="{ elCard: isLine }"
            @click.native="dedicated"
            >增值税专用发票</el-card
          >
        </div>
      </div>
      <div style="padding: 30px">
        <div v-if="typeCommon">
          <div style="margin-bottom: 30px">开票信息：</div>
          <el-form
            ref="ticket"
            :model="ticket"
            label-width="130px"
            style="margin-top: 20px; width: 50%"
            :rules="ticketObj"
          >
            <el-form-item label="企业名称">
              <el-input v-model="ticket.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="税号">
              <el-input v-model="ticket.paragraph" disabled></el-input>
            </el-form-item>
            <div style="margin-top: 40px; margin-bottom: 30px">
              发票接收信息：
            </div>
            <el-form-item label="邮寄地址" prop="mail">
              <el-input v-model="ticket.mail"></el-input>
            </el-form-item>
            <el-form-item label="接收人" prop="person">
              <el-input v-model="ticket.person"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contact">
              <el-input v-model="ticket.contact"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="electronic">
              <el-input v-model="ticket.electronic"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitTicket('ticket')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div v-if="typeDedicated">
          <div style="margin-bottom: 30px">开票信息：</div>
          <el-form
            ref="major"
            :model="major"
            label-width="130px"
            style="margin-top: 20px; width: 50%"
            :rules="majorObj"
          >
            <el-form-item label="企业名称">
              <el-input v-model="major.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="税号">
              <el-input v-model="major.paragraph" disabled></el-input>
            </el-form-item>
            <el-form-item label="企业地址" prop="site">
              <el-input v-model="major.site"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="telephone">
              <el-input v-model="major.telephone"></el-input>
            </el-form-item>
            <el-form-item label="开户行名称" prop="opening">
              <el-input v-model="major.opening"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account">
              <el-input v-model="major.account"></el-input>
            </el-form-item>
            <!-- <el-form-item label="一般纳税人登记表">
              <span class="befor_1">*</span>
              <el-upload
                class="upload-demo"
                action="api/newGuarantee.do?callBidFileUpLoad"
                name="taxpayerRegisterFile"
                :on-success="success"
              >
                <el-button>上传文件</el-button>
              </el-upload>
            </el-form-item> -->
            <div style="margin-top: 40px; margin-bottom: 30px">
              发票接收信息：
            </div>
            <el-form-item label="邮寄地址" prop="area">
              <el-input v-model="major.area"></el-input>
            </el-form-item>
            <el-form-item label="接收人" prop="consignee">
              <el-input v-model="major.consignee"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="major.phone"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="email">
              <el-input v-model="major.email"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitMajor('major')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="申请详情" :visible.sync="details" size="50%">
      <div
        style="
          padding: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
        v-if="this.detailsOnTheInvoice.ticket_type == 1"
      >
        <p style="text-indent: 2em">发票类型：增值税普通发票</p>
        <p style="text-indent: 2em">
          申请时间：{{ this.detailsOnTheInvoice.create_date }}
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 1"
      >
        <p style="text-indent: 2em">开票信息</p>
        <p style="text-indent: 4em">
          企业名称：{{ this.detailsOnTheInvoice.ticket_title }}
        </p>
        <p style="text-indent: 6em">
          税号：{{ this.detailsOnTheInvoice.tax_number }}
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 1"
      >
        <p>发票邮寄信息</p>
        <p style="text-indent: 4em">
          邮寄地址：{{ this.detailsOnTheInvoice.receive_address }}
        </p>
        <p style="text-indent: 5em">
          接收人：{{ this.detailsOnTheInvoice.receive_name }}
        </p>
        <p style="text-indent: 4em">
          联系电话：{{ this.detailsOnTheInvoice.receive_phone }}
        </p>
        <p style="text-indent: 4em">
          电子邮箱：{{ this.detailsOnTheInvoice.address_mail }}
        </p>
      </div>

      <div
        style="
          padding: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
        v-if="this.detailsOnTheInvoice.ticket_type == 2"
      >
        <p style="text-indent: 2em">发票类型：增值税专用发票</p>
        <p style="text-indent: 2em">
          申请时间：{{ this.detailsOnTheInvoice.create_date }}
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 2"
      >
        <p style="text-indent: 2em">开票信息</p>
        <p style="text-indent: 4em">
          企业名称：{{ this.detailsOnTheInvoice.ticket_title }}
        </p>
        <p style="text-indent: 6em">
          税号：{{ this.detailsOnTheInvoice.tax_number }}
        </p>
        <p style="text-indent: 4em">
          企业地址：{{ this.detailsOnTheInvoice.address_company }}
        </p>
        <p style="text-indent: 6em">
          电话：{{ this.detailsOnTheInvoice.phone_company }}
        </p>
        <p style="text-indent: 3em">
          开户行名称：{{ this.detailsOnTheInvoice.khh }}
        </p>
        <p style="text-indent: 6em">
          账号：{{ this.detailsOnTheInvoice.bank_account }}
        </p>
        <!-- <p>
          一般纳税人登记表：
          <el-button size="small" @click="registration">查看</el-button>
        </p> -->
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 2"
      >
        <p>发票邮寄信息</p>
        <p style="text-indent: 4em">
          邮寄地址：{{ this.detailsOnTheInvoice.receive_address }}
        </p>
        <p style="text-indent: 5em">
          接收人：{{ this.detailsOnTheInvoice.receive_name }}
        </p>
        <p style="text-indent: 4em">
          联系电话：{{ this.detailsOnTheInvoice.receive_phone }}
        </p>
        <p style="text-indent: 4em">
          电子邮箱：{{ this.detailsOnTheInvoice.address_mail }}
        </p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import downloadjs from "downloadjs";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      orderNum: null,
      projectName: null,
      invoiceStatus: null,
      invoiceArr: [
        {
          value: 0,
          label: "未申请",
        },
        {
          value: 1,
          label: "开票中",
        },
        {
          value: 2,
          label: "已开票",
        },
      ],
      applyDate: null,
      tableData: [],
      total: 0,
      size: 10,
      current: 1,
      drawer: false,
      form: {
        projectName: "",
        beneficiary: "",
        warrantee: "",
        money: "",
        bidOpeningDate: "",
        validity: "",
        lengthen: "",
        state: "",
        type: "",
        orderAmount: "",
        createData: "",
        orderStatus: "",
        omit_relative_path: "",
        gua_money_upper: "",
        bid_end_date: "",
        ticket_status: "",
        tendering_file_url: "",
        omit_file_url: "",
      },
      invoice: false,
      typeCommon: false,
      typeDedicated: false,
      isActive: false,
      isLine: false,
      ticket: {
        name: "",
        paragraph: "",
        mail: "",
        person: "",
        contact: "",
        electronic: "",
      },
      ticketObj: {
        mail: [
          { required: true, message: "邮寄地址不可为空", trigger: "blur" },
        ],
        person: [
          { required: true, message: "接收人不可为空", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "联系电话不可为空", trigger: "blur" },
        ],
        electronic: [
          { required: true, message: "电子邮箱不可为空", trigger: "blur" },
        ],
      },
      major: {
        name: "",
        paragraph: "",
        site: "",
        telephone: "",
        opening: "",
        account: "",
        area: "",
        consignee: "",
        phone: "",
        email: "",
        registerUrl: "",
        registerUrlName: "",
      },
      majorObj: {
        site: [
          { required: true, message: "企业地址不可为空", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "电话不可为空", trigger: "blur" },
        ],
        opening: [
          { required: true, message: "开户行名称不可为空", trigger: "blur" },
        ],
        account: [{ required: true, message: "账号不可为空", trigger: "blur" }],
        area: [
          { required: true, message: "邮寄地址不可为空", trigger: "blur" },
        ],
        consignee: [
          { required: true, message: "接收人不可为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "联系电话不可为空", trigger: "blur" },
        ],
        email: [
          { required: true, message: "电子邮箱不可为空", trigger: "blur" },
        ],
      },
      details: false,
      firmName: "",
      CAKeyEncStr: "",
      dialogVisible: false,
      content: "",
      ticket_type: "",
      detailsOnTheInvoice: {
        create_date: "",
        ticket_title: "",
        tax_number: "",
        receive_address: "",
        receive_name: "",
        receive_phone: "",
        address_mail: "",
        address_company: "",
        phone_company: "",
        khh: "",
        bank_account: "",
      },
      checked: false,
      dialogFormVisible: false,
      filePath: "",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    search() {
      this.current = 1;
      this.getDataList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDataList();
    },
    // 获取数据列表
    getDataList() {
      var applyDate = "";
      if (this.applyDate == "" || this.applyDate == null) {
        applyDate = null;
      } else {
        applyDate = this.moment(this.applyDate).format("YYYY-MM-DD");
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?getInvoiceList",
        params: {
          CAKeyId: this.firmName,
          from: 10,
          page: this.current,
          pageNum: this.size,
          projectName: this.projectName,
          orderNum: this.orderNum,
          applyDate: applyDate,
          invoiceStatus: this.invoiceStatus,
        },
      }).then((res) => {
        this.total = res.data.data.count;
        this.tableData = res.data.data.items;
      });
    },
    open(e) {
      this.invoice = true;
      this.orderNum = e.orderNum;
    },
    // 点击重置
    reset() {
      this.current = 1;
      this.orderNum = "";
      this.projectName = "";
      this.applyDate = "";
      this.invoiceStatus = "";
      this.getDataList();
    },
    getEnterpriseMessage() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: this.CAKeyEncStr,
        };
      } else if (type == "CFCA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      } else {
        var params = {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (
          res.data.data.userStatus == "1" ||
          (res.data.data.userStatus == "3" && !res.data.data.apply)
        ) {
          this.$confirm("企业信息未完善,请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        } else if (res.data.data.userStatus == "3" && res.data.data.apply) {
          this.$confirm(
            res.data.data.errorInfo + "，请先完善企业信息",
            "提示",
            {
              confirmButtonText: "确定",
              closeOnPressEscape: false,
              closeOnClickModal: false,
              closeOnHashChange: false,
              showCancelButton: false,
              showClose: false,
              type: "warning",
            }
          ).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        }
      });
    },
    // 点击普通发票
    common() {
      this.isLine = false;
      this.typeDedicated = false;
      this.typeCommon = !this.typeCommon;
      this.isActive = !this.isActive;
    },

    // 点击专用发票
    dedicated() {
      this.isActive = false;
      this.typeCommon = false;
      this.typeDedicated = !this.typeDedicated;
      this.isLine = !this.isLine;
    },

    // 普通发票点击提交
    submitTicket(ticket) {
      this.$refs[ticket].validate((valid) => {
        if (valid) {
          // 成功
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?addTicketApply",
            params: {
              companyName: this.ticket.name,
              creditCode: this.ticket.paragraph,
              receiveAddress: this.ticket.mail,
              receiveName: this.ticket.person,
              receivePhone: this.ticket.contact,
              emailAddress: this.ticket.electronic,
              ticketType: 1,
              orderNum: this.orderNum,
              moneyAmount: "0",
              companyAccount: "",
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "申请发票成功",
                type: "success",
              });
              this.invoice = false;
              this.drawer = false;
              this.getDataList();
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          // 失败
          return false;
        }
      });
    },

    // 专用发票上传文件
    success(response) {
      if (response.success == true) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.major.registerUrl = response.attributes.filePath;
        this.major.registerUrlName = response.obj;
      } else {
        this.$message({
          message: "上传文件失败，" + response.msg,
          type: "error",
        });
      }
    },

    // 专用发票点击提交
    submitMajor(major) {
      this.$refs[major].validate((valid) => {
        if (valid) {
          // 成功
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?addTicketApply",
            params: {
              companyName: this.major.name,
              creditCode: this.major.paragraph,
              receiveAddress: this.major.area,
              receiveName: this.major.consignee,
              receivePhone: this.major.phone,
              emailAddress: this.major.email,
              ticketType: 2,
              orderNum: this.orderNum,
              moneyAmount: "0",
              companyAccount: this.major.account,
              companyAddress: this.major.site,
              companyPhone: this.major.telephone,
              companyKhh: this.major.opening,
              registerUrl: this.major.registerUrl,
              registerUrlName: this.major.registerUrlName,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "申请发票成功",
                type: "success",
              });
              this.invoice = false;
              this.drawer = false;
              this.getDataList();
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          // 失败
          return false;
        }
      });
    },

    // 点击申请发票详情
    checkDetails(e) {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?findTicketInfo",
        params: {
          orderNum: e.orderNum,
        },
      }).then((res) => {
        if (res.data.data.items[0].ticket_type == "普票") {
          this.detailsOnTheInvoice.ticket_type = 1;
          this.detailsOnTheInvoice.create_date = this.moment(
            res.data.data.items[0].create_date
          ).format("YYYY-MM-DD");
          this.detailsOnTheInvoice.ticket_title =
            res.data.data.items[0].ticket_title;
          this.detailsOnTheInvoice.tax_number =
            res.data.data.items[0].tax_number;
          this.detailsOnTheInvoice.receive_address =
            res.data.data.items[0].receive_address;
          this.detailsOnTheInvoice.receive_name =
            res.data.data.items[0].receive_name;
          this.detailsOnTheInvoice.receive_phone =
            res.data.data.items[0].receive_phone;
          this.detailsOnTheInvoice.address_mail =
            res.data.data.items[0].address_mail;
        } else {
          this.detailsOnTheInvoice.create_date = this.moment(
            res.data.data.items[0].create_date
          ).format("YYYY-MM-DD");
          this.detailsOnTheInvoice.ticket_title =
            res.data.data.items[0].ticket_title;
          this.detailsOnTheInvoice.tax_number =
            res.data.data.items[0].tax_number;
          this.detailsOnTheInvoice.receive_address =
            res.data.data.items[0].receive_address;
          this.detailsOnTheInvoice.receive_name =
            res.data.data.items[0].receive_name;
          this.detailsOnTheInvoice.receive_phone =
            res.data.data.items[0].receive_phone;
          this.detailsOnTheInvoice.address_mail =
            res.data.data.items[0].address_mail;
          this.detailsOnTheInvoice.ticket_type = 2;
          this.detailsOnTheInvoice.address_company =
            res.data.data.items[0].address_company;
          this.detailsOnTheInvoice.phone_company =
            res.data.data.items[0].phone_company;
          this.detailsOnTheInvoice.khh = res.data.data.items[0].khh;
          this.detailsOnTheInvoice.bank_account =
            res.data.data.items[0].bank_account;
          this.detailsOnTheInvoice.register_file_url =
            res.data.data.items[0].register_file_url;
        }
        this.details = true;
      });
    },

    // 点击一般纳税人登记表查看
    registration() {
      var tendering = this.detailsOnTheInvoice.register_file_url.replace(
        /\\/g,
        "/"
      );
      var str =
        "https://www.haneton.com/hetinterface/hetInterfaceController.do?getPdfFile" +
        "&filePath=" +
        tendering;
      window.open(str, "_blank");
    },

    // 点击返回
    getBack() {
      this.$router.push("/main");
    },
    //
    huixian(e) {
      this.axios({
        method: "POST",
        url: "/newGuarantee.do?getCustmomerTicketInfo",
        data: {
          companyName: this.ticket.name,
          CAKeyId: this.firmName,
          from: 10,
        },
      }).then((res) => {
        if (res.data.data) {
          console.log("11111111111", res);
          this.ruleForm = res.data.data;
          this.invoice = true;
          this.orderNum = e.orderNum;
          this.ticket.mail = res.data.data.receiveAddress;
          this.ticket.person = res.data.data.receiveName;
          this.ticket.contact = res.data.data.receivePhone;
          this.ticket.electronic = res.data.data.receiveEmail;
          this.major.site = res.data.data.companyAddress;
          this.major.telephone = res.data.data.companyPhone;
          this.major.opening = res.data.data.companyBankName;
          this.major.account = res.data.data.companyBankAccount;
          this.major.area = res.data.data.receiveAddress;
          this.major.consignee = res.data.data.receiveName;
          this.major.phone = res.data.data.receivePhone;
          this.major.email = res.data.data.receiveEmail;
        } else {
          this.$confirm("请先完善企业开票信息", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/kaipiao");
            })
            .catch(() => {
              this.$router.push("/kaipiao");
            });
        }
      });
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.firmName = window.sessionStorage.getItem("firmName");
    this.CAKeyEncStr = window.sessionStorage.getItem("date");
    this.ticket.paragraph = window.sessionStorage.getItem("socialCode");
    this.major.paragraph = window.sessionStorage.getItem("socialCode");
    this.ticket.name = window.sessionStorage.getItem("com_name");
    this.major.name = window.sessionStorage.getItem("com_name");
    this.getDataList();
    this.getEnterpriseMessage();
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.invoice {
  height: 100%;
}
.elCard {
  background-color: rgba(51, 126, 190, 1) !important;
  color: #fff;
}
.text {
  text-indent: 2em;
}
.befor_1 {
  position: absolute;
  left: -137px;
  color: #f56c6c;
}
</style>